<script>
import { onMounted, onUnmounted, ref, provide } from "vue";
import "animate.css";
// import { _ } from "../WebsiteOfficial/assetdir/menu_icon.1fb4242d";
var page_url = window.location.href.split("#")[0];
var encoded_url = encodeURIComponent(page_url); //处理微信分享
import lodash_ from "lodash"


// jquery.ajax({
//   type: "get",
//   data: {
//     url: encoded_url,
//   },
//   dataType: "jsonp",
//   url: "https://www.uniskin.com.cn/mall/member/account/wxjssdkReport",
//   success:function(wx_data){
//       console.log("wxdata",wx_data);
//       wx.config({
//       debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//       appId: wx_data.sign.appId, // 必填，公众号的唯一标识
//       timestamp: wx_data.sign.timestamp, // 必填，生成签名的时间戳
//       nonceStr: wx_data.sign.nonceStr, // 必填，生成签名的随机串
//       signature: wx_data.sign.signature, // 必填，签名
//       jsApiList: [
//         "onMenuShareTimeline",
//         "onMenuShareAppMessage",
//         "downloadImage",
//       ], // 必填，需要使用的JS接口列表
//     });

//     wx.ready(function () {
//       //需在用户可能点击分享按钮前就先调用
//       console.log("ready")
//     });
//   }
// })

</script>
<script setup>
let is_mobile = ref((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? true : false) ;
provide('is_mobile', is_mobile);


// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
let last_scrolltop = 0;
// let nav_height = document.querySelector(".menu_view").offsetHeight;
// console.log("nav_height",nav_height);
let calcScroll = () => {
  let c_scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  let scroll = c_scrolltop - last_scrolltop;
  last_scrolltop = c_scrolltop;
  let mobile_menu_dom = document.querySelector(".menu_view");
  if(scroll < 0 || c_scrolltop <50){
    if(mobile_menu_dom){
      mobile_menu_dom.classList.remove("hide_menu");
    }
  }else{
    if(mobile_menu_dom){
      mobile_menu_dom.classList.add("hide_menu");
    }
  }
  
  console.log("debouce",c_scrolltop,"calc",scroll);
}
let _calcScroll = lodash_.debounce(calcScroll,50);
  onMounted(()=>{
    window.addEventListener('scroll',
      _calcScroll
      , true)
    window.addEventListener("resize", () => {
    if (window.innerWidth <= 768) {
      is_mobile.value = true;
    } else {
      is_mobile.value = false;
    }
    console.log("is_mobile main", is_mobile.value);
  })
  })

onUnmounted(() => {
  window.removeEventListener("resize")
})

</script>

<template>
  
  <!-- <router-view></router-view> -->

  <router-view v-slot="{ Component, route }">
    <transition name="fade" mode="out-in">
      <!-- <keep-alive> -->
      <component :is="Component"></component>
      <!-- </keep-alive> -->
    </transition>
  </router-view>
</template>

<style lang="less">
@import "./modules/reset.less";
@import "./modules/adapter.css";
@import "./modules/mixin.less";
// @import "./modules/iconfont.css";



// @import './modules/mixin.less';
#app {
  //   font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: uniskin-en-ex;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/AktivGroteskEx_Md.ttf");
  unicode-range: U+0000-007F; /* 英文字母和基本符号 */
}

@font-face {
  font-family: uniskin-en-l;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/AktivGroteskEx_Lt.ttf");
  unicode-range: U+0000-007F; /* 英文字母和基本符号 */
}
@font-face {
  font-family: uniskin-en-rg;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/AktivGroteskEx_Rg.ttf");
  unicode-range: U+0000-007F; /* 英文字母和基本符号 */
}
@font-face {
  font-family: uniskin-en-normal-md;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/AktivGrotesk_Md.ttf");
  unicode-range: U+0000-007F; /* 英文字母和基本符号 */
}

@font-face {
  font-family: uniskin-en-normal-l;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/AktivGrotesk_Lt.ttf");
  unicode-range: U+0000-007F; /* 英文字母和基本符号 */
}
@font-face {
  font-family: uniskin-en-normal-rg;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/AktivGrotesk_Rg.ttf");
  unicode-range: U+0000-007F; /* 英文字母和基本符号 */
}

@font-face {
  font-family: uniskin-cn-md;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/SourceHanSansCN-Medium.otf");
  unicode-range: U+4E00-9FFF, U+3000-303F; /* 常用汉字和标点符号 */
}

@font-face {
  font-family: uniskin-cn-light;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/SourceHanSansCN-Light.otf");
  unicode-range: U+4E00-9FFF, U+3000-303F; /* 常用汉字和标点符号 */
}
@font-face {
  font-family: uniskin-cn-rg;
  src: url("https://staticcdn.inertiabiotech.com/uniskin/font/official/SourceHanSansCN-Regular.otf");
  unicode-range: U+4E00-9FFF, U+3000-303F; /* 常用汉字和标点符号 */
}
html,
body {
  background-color: #fff;
  // font-family:uniskin-en-normal-l,uniskin-cn-light, sans-serif;
  font-family:uniskin-cn-light, sans-serif;
  font-weight:normal;
  // max-width:960px;
  margin:0 auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
	will-change: transform;
	transition: all 300ms;
	/* position: absolute; */
}
.slide-right-enter {
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
}
.ios_class{
  line-height:0.76rem!important;
}

//caidan
.menu_view{
  position:relative;
  top:0;
  left:0;
  -webkit-transition:all 0.3s;
  transition:all 0.3s;
  &.hide_menu{
    top:-2.2rem;
    left:0;
    
  }
    // position:relative;
    box-sizing:border-box;
    padding-top:0.7rem;
    width:100%;
    height:2.2rem;
    position:fixed;
    top:0;
    left:0;
    z-index:998;
    background-color:#fff;
    text-align:center;
    .menu_shadow;
    .logo{
      vertical-align:top;
        width:4.4rem;
        height:0.68rem;
    }
    .menu_icon{
        position:absolute;
        left:0.64rem;
        top:50%;
        -webkit-transform:translateY(-50%) scale(1.2);
        transform:translateY(-50%) scale(1.2);
        width:0.64rem;
        height:0.4rem;
        padding:0.3rem 0;
        // object-fit:cover;
    }
}
.parent_view{
  padding-top:2.2rem;
}
.cn_rg{
  font-family:uniskin-cn-rg;
}
.en_md{
  font-family: uniskin-en-normal-md;
}
.cn_lt{
  font-family:uniskin-cn-light;
}
.cn_md{
  font-family:uniskin-cn-md;
}
.en_rg{
  font-family:uniskin-en-normal-rg;
}
.en_lt{
  font-family:uniskin-en-normal-l;
}
.padding_side_44{
    padding-left:0.88rem;
    padding-right:0.88rem;
}
.padding_side_30{
    padding-left:0.6rem;
    padding-right:0.6rem;
}
	
.font_32{
    font-size:0.64rem;
    line-height:0.84rem;
	
}
	
.font_60 {
	font-family:cn_rg!important;
    font-size:1.2rem;
   line-height:1.44rem;
	
}
	
.gray_bg{
	
   background-color:#efefef;
	
 }
 .cursor_pointer{
  cursor:pointer;
 }

</style>
